* {
    margin: 0;
    padding: 0;
}

body {
    text-align: center;
}

.column {
    margin: auto;
    margin-top: 20vh;
    height: 60vh;
    width: 40vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
}

button {
    width: 100%;
    font-size: 1.2rem;
    background-color: #77E0D3;
    color: #ffffff;
    border: none;
    border-radius: 16px;
    margin: 20px;
    padding: 16px 32px;
    cursor: pointer;
}